<template>
  <div>
    <b-row
      class="match-height"
    >
      <b-col
        v-if="activeSubscription"
        sm="12"
        md="12"
      >
        <b-card>
          <b-button
            v-if="!activeSubscription.is_paid"
            variant="primary"
            class="bg-primary p-1 px-3 text-white shadow cursor-pointer"
            style="position:absolute;top:0; right:0;border-radius: 0px 8px 0px 15px;z-index:300;"
            :to="{ name: 'subscribe', params: { id: activeSubscription.id } }"
          >
            <span class="cursor-pointer">Pay Now</span>
          </b-button>
          <b-row class="ma-0 h-full">
            <b-col>
              <b-card-title class="d-flex justify-content-start mt-0 mb-1">
                <h2>Active Subscription</h2>
                <div class="ml-2">
                  <b-badge
                    :variant="`light-${activeSubscription.is_paid ? 'primary' : 'danger'}`"
                  >
                    {{ activeSubscription.is_expire ? 'Expired' : activeSubscription.is_paid ? 'Paid' : 'Unpaid' }}
                  </b-badge>
                </div>
              </b-card-title>
              <h4>Subscription value - {{ activeSubscription.price }}</h4>
              <div class="progress-wrapper">
                <b-card-text class="font-weight-bolder d-flex justify-content-end">
                  {{ subscriptionTimer.remainingDays }} of {{ subscriptionTimer.totalDays }} Days
                </b-card-text>
                <b-progress
                  v-model="subscriptionTimer.remainingDays"
                  max="30"
                />
                <b-card-text class="mt-0">
                  {{ subscriptionTimer.remainingDays }} Day{{ subscriptionTimer.remainingDays > 0 ? 's' : '' }} Remaining
                </b-card-text>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-card no-body>
      <b-row class="d-flex justify-content-between align-items-center ml-1 mr-2">
        <b-card-header>
          <h4>All Subscriptions</h4>
        </b-card-header>
        <div>
          <JsonExcel
            class="btn p-0"
            :data="rows"
            type="csv"
            :escape-csv="false"
            :name="`Subscriptions - Page ${page}.xls`"
          >
            <b-button
              v-b-tooltip.hover.v-primary="'Download Excel - Page'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="mr-1"
              size="sm"
            >
              <mdicon
                name="Download"
                size="15"
              />
            </b-button>
          </JsonExcel>
          <JsonExcel
            class="btn p-0"
            :fetch="fetchSubscriptionListNoPagination"
            :before-generate="__showJsonDownloadAlert"
            :fields="json_fields"
            type="csv"
            :escape-csv="false"
            name="Subscriptions.xls"
          >
            <b-button
              v-b-tooltip.hover.v-primary="'Download Excel - All'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="mr-1"
              size="sm"
            >
              <mdicon
                name="DownloadMultiple"
                size="15"
              />
            </b-button>
          </JsonExcel>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover.v-primary="'Refresh Table'"
            variant="outline-primary"
            size="sm"
            @click="onClickRefresh()"
          >
            <feather-icon
              icon="RefreshCwIcon"
            />
          </b-button>
        </div>
      </b-row>
      <b-row>
        <b-col>
          <b-overlay
            id="overlay-background"
            blur="10px"
            opacity="0.30"
            rounded="sm"
            variant="light"
          >
            <b-table
              striped
              hover
              responsive
              :per-page="perPage"
              :items="rows"
              :fields="fields"
              show-empty
            >
              <template #empty>
                <TableDataFetching
                  :rows="rows"
                  :data-loading="dataLoading"
                />
              </template>
              <template #cell(client_name)="data">
                <div
                  class="d-flex align-items-center"
                >
                  <b-avatar
                    v-if="data.item.logo === null"
                    :text="avatarText(data.value)"
                    size="32"
                    variant="light-primary"
                  />
                  <b-avatar
                    v-else
                    :src="`${data.item.logo}`"
                    size="32"
                  />
                  <div>
                    <div
                      class="font-weight-bolder ml-1 text-primary"
                    >
                      {{ data.value }}
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(package_count)="data">
                <div
                  class="text-primary"
                  @click="openPackageListModal(data.item.id)"
                >
                  {{ data.value }} Package {{ data.value > 1 ? 's' : '' }}
                </div>
              </template>
              <template #cell(price)="data">
                {{ data.value || '---' }}
              </template>
              <template #cell(paid_via)="data">
                {{ data.value || '---' }}
              </template>
              <template #cell(starts_at)="data">
                {{ __dateTimeFormatter(data.value) }}
              </template>
              <template #cell(ends_at)="data">
                {{ __dateTimeFormatter(data.value) }}
              </template>
              <template #cell(created_at)="data">
                {{ __dateTimeFormatter(data.value) }}
              </template>
              <template #cell(is_paid)="data">
                <b-badge
                  :variant="`light-${data.value ? 'success' : 'danger'}`"
                >
                  {{ data.value ? 'Paid' : 'Unpaid' }}
                </b-badge>
              </template>
              <template #cell(action)="data">
                {{ data.value }}
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="12"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    @click="onClickViewPayment(data.item.id)"
                  >
                    <feather-icon icon="EyeIcon" />
                    View
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="!data.item.is_paid"
                    :to="{ name: 'subscribe', params: { id: data.item.id } }"
                  >
                    <feather-icon icon="CreditCardIcon" />
                    Pay Now
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-overlay>

          <div class="mx-2 mb-2">
            <b-row
              cols="12"
            >
              <b-col
                class="d-flex align-items-center justify-content-sm-start"
                md="3"
              >
                <label class="width-75">Per page</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block width-100"
                />
              </b-col>
              <!-- Pagination -->
              <b-col
                class="d-flex align-items-center justify-content-sm-end"
                md="9"
              >
                <span class="text-muted mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
                <b-pagination
                  v-model="page"
                  :per-page="perPage"
                  :total-rows="0"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <view-payment-modal ref="viewPaymentModal" />
    <package-list-modal ref="packageListModal" />
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BCard,
  BPagination,
  BTable,
  BOverlay,
  BCardHeader,
  BCardText,
  BCardTitle,
  BButton,
  BBadge,
  BDropdown,
  BDropdownItem,
  BProgress,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import JsonExcel from 'vue-json-excel'
import Filter from '@core/mixins/filter'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import ViewPaymentModal from '@/components/SubscriptionPlan/ViewPaymentModal.vue'
import PackageListModal from '@/components/subscription/PackageListModal.vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const subscriptionRepository = RepositoryFactory.get('subscription')

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCol,
    BRow,
    BCard,
    BPagination,
    BTable,
    BCardHeader,
    BCardText,
    BBadge,
    BCardTitle,
    BButton,
    BOverlay,
    BProgress,
    BDropdown,
    BDropdownItem,
    vSelect,
    ViewPaymentModal,
    PackageListModal,
    JsonExcel,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  data() {
    return {
      total: 0,
      meta: {},
      dataLoading: false,
      sort: '',
      filters: {},
      subscriptionTimer: {
        total_days: '',
      },
      perPage: 20,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      page: 1,
      fields: [
        {
          key: 'id',
          label: '#',
          thClass: 'd-none',
          tdClass: 'd-none',
        },
        {
          key: 'package_count',
          label: 'Packages',
          sortable: true,
        },
        {
          key: 'starts_at',
          label: 'Started Date',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'ends_at',
          label: 'End Date',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'price',
          label: 'Price',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'paid_via',
          label: 'Paid Via',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'is_paid',
          label: 'Is Paid',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'created_at',
          label: 'Created At',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'action',
          label: 'Action',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      rows: [],
      activeSubscription: null,
    }
  },
  async mounted() {
    await this.fetchActiveSubscription()
  },
  methods: {
    async fetchActiveSubscription() {
      try {
        const { data } = (await subscriptionRepository.geActiveSubscription())
        this.activeSubscription = data.data
        this.subscriptionTimer.totalDays = Math.floor((new Date(this.activeSubscription.ends_at) - new Date(this.activeSubscription.starts_at)) / (1000 * 60 * 60 * 24)) + 1
        this.subscriptionTimer.remainingDays = this.subscriptionTimer.totalDays - Math.floor((new Date() - new Date(this.activeSubscription.starts_at)) / (1000 * 60 * 60 * 24)) - 1
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async fetchSubscriptionList() {
      this.dataLoading = true
      this.rows = []
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await subscriptionRepository.getSubscriptionList())
        this.rows = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    async fetchSubscriptionListNoPagination() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await subscriptionRepository.getSubscriptionListNopagination())
        return data.data
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
    onClickViewPayment(id) {
      this.$refs.viewPaymentModal.openModal(id)
    },
    onClickMakePayment() {
      this.$router.push({
        name: 'subscribe',
      })
    },
    openPackageListModal(id) {
      this.$refs.packageListModal.openModal(id)
    },
    filterQueryUpdate() {
      this.fetchSubscriptionList()
    },
    sortChanged(e) {
      const key = e.sortBy
      if (key) {
        const active = e.sortDesc ? '-' : ''
        this.sort = active + key
        this.fetchSubscriptionList()
      }
    },
    onClickRefresh() {
      this.fetchActiveSubscription()
      this.fetchSubscriptionList()
    },
  },
}
</script>
