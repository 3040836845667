<template>
  <!--      Modal Actual -->
  <b-modal
    id="view-payment-modal"
    ref="modal"
    v-model="open"
    size="lg"
    no-close-on-backdrop
    hide-footer
  >
    <b-overlay
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <section>
        <b-card
          id="invoice-preview-card"
          no-body
          class="invoice-preview-card"
        >
          <b-card-body
            class="invoice m-2"
          >
            <b-row class="invoice-spacing">

              <!-- Col: Invoice To -->
              <b-col
                cols="12"
                class="pb-1"
              >
                <h4 class="pb-1">
                  Payment Details
                </h4>
                <h6>
                  Business Name:&nbsp;  {{ courier.company_name }}
                </h6>
                <h6>
                  Email:&nbsp;  {{ courier.company_finance_email }}
                </h6>
                <h6>
                  Address:&nbsp;  {{ courier.company_address }}
                </h6>
                <h6>
                  Phone:&nbsp;  {{ courier.company_phone }}
                </h6>
              </b-col>
            </b-row>
            <b-table
              striped
              bordered
              responsive
              :items="rows"
              :fields="fields"
              show-empty
            >
              <template #empty>
                <TableDataFetching
                  :rows="rows"
                  :data-loading="dataLoading"
                />
              </template>
              <template #cell(interval)="data">
                <div>
                  {{ `${data.item.interval_count} ${data.item.interval}` }}
                </div>
              </template>
            </b-table>
            <b-row>
              <b-col
                cols="12"
                md="12"
                class="pt-1"
              >
                <div>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          Created At:
                        </td>
                        <td><span>{{ subscriptionDetails.created_at }}</span></td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Transaction ID:
                        </td>
                        <td><span>{{ subscriptionDetails.transaction_id || '---' }}</span></td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Paid Via:
                        </td>
                        <td><span>{{ subscriptionDetails.paid_via || '---' }}</span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>

        <!-- Right Col: Card -->
        <b-col
          cols="12"
          md="4"
        >
          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-success"
            class="mb-2 ml-2"
            block
            @click="printPaymentDetails"
          >
            <feather-icon
              icon="PrinterIcon"
            />
            Print
          </b-button>
        </b-col>
      </section>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BCard,
  BButton,
  BModal,
  BCol,
  BCardBody,
  BRow,
  BOverlay,
  VBModal,
  BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const subscriptionRepository = RepositoryFactory.get('subscription')

function initialState() {
  return {
    open: false,
    dataLoading: true,
    courier: {},
    subscriptionId: '',
    fields: [
      {
        key: 'name',
        label: 'Package',
      },
      {
        key: 'description',
        label: 'Description',
        tdClass: 'text-center',
        thClass: 'text-center',
      },
      {
        key: 'interval',
        label: 'Interval',
        tdClass: 'text-center',
        thClass: 'text-center',
      },
    ],
    rows: [],
    allData: [],
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BCard,
    BButton,
    BModal,
    BCol,
    BCardBody,
    BRow,
    BOverlay,
    BTable,
  },
  data() {
    return initialState()
  },
  methods: {
    async openModal(id) {
      await Object.assign(this.$data, initialState())
      this.open = true
      this.subscriptionId = id
      this.courier = JSON.parse(localStorage.getItem('businessSettings'))
      await this.getSubscriptionDetails()
      await this.getSubscriptionPackages()
    },
    async getSubscriptionDetails() {
      try {
        const { data } = (await subscriptionRepository.getSubscriptionById(this.subscriptionId)).data
        this.subscriptionDetails = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async getSubscriptionPackages() {
      try {
        const { data } = (await subscriptionRepository.getSubscriptionPackages(this.subscriptionDetails.id)).data
        this.rows = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    printPaymentDetails() {
      const modal = document.getElementById('invoice-preview-card')
      const cloned = modal.cloneNode(true)
      let section = document.getElementById('print')

      if (!section) {
        section = document.createElement('div')
        section.id = 'print'
        document.body.appendChild(section)
      }

      section.appendChild(cloned)
      window.print()
      section.innerHTML = ''
    },
  },
}
</script>
<style lang="scss">
@page {
  width: 80mm;
}
@media screen {
  #print {
    display: none;
  }
}
@media print {
  body * {
    visibility:hidden;
  }
  #print, #print * {
    visibility:visible;
  }
  #print {
    position:absolute;
    left:0;
    top:0;
    width: 100%;
  }
}
</style>
<style scoped>
@import "../../../node_modules/@syncfusion/ej2-base/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-vue-navigations/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-buttons/styles/fabric.css";
</style>
